
import {computed, defineComponent, nextTick, onMounted, ref} from "vue";

import {
    setCurrentPageBreadcrumbs,
    setCurrentPageTitle,
} from "@/core/helpers/breadcrumb";
import {useStore} from "vuex";

import {Actions} from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

import ExclamationNotice from "@/components/my-components/notices/exclamation-notice.vue";
import {ErrorMessage, Field, Form} from "vee-validate";

import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

import NoMoneyRequest from "@/components/my-components/no-money-request.vue";
import {useToast} from "vue-toastification";

import PaymentMethods from "@/components/my-components/main-page/payments/PaymentMethods.vue";
import {reinitializeComponents} from "@/core/plugins/keenthemes";

export default defineComponent({
    name: "overview",
    components: {
        ExclamationNotice,
        Field,
        Form,
        ErrorMessage,
        NoMoneyRequest,
        PaymentMethods,
    },
    setup() {
        const store = useStore();
        const toast = useToast();

        const no_money_request = Yup.object().shape({
            short_description: Yup.string(),
        });

        const userData = computed(() => {
            return store.getters.currentUser;
        });

        const userPaymentsStatistics = computed(() => {
            return store.getters.getPaymentsStatistics;
        });

        const userPaymentsList = computed(() => {
            return store.getters.getUserPaymentsList;
        });

        onMounted(() => {
            setCurrentPageBreadcrumbs("Оплати", ["Profile", "Payments"]);
            setCurrentPageTitle("Оплати");

            nextTick(() => {
                reinitializeComponents();
            });
        });

        let in_progress = ref(false);

        const sendNoMoneyRequest = async (values) => {
            Swal.fire({
                text: "Надіслати запит на розгляд проблеми ?",
                title: "Допомога за оплати",
                icon: "question",
                buttonsStyling: false,
                confirmButtonText: "Так",
                showCancelButton: true,
                cancelButtonText: "Скасувати",
                customClass: {
                    confirmButton: "btn fw-bold btn-light-success",
                    cancelButton: "btn fw-bold btn-light-danger",
                },
            }).then((value) => {
                if (value.isConfirmed) {
                    in_progress.value = true;

                    store.dispatch(Actions.NO_MONEY_SEND_REQUEST, {
                        login: userData.value.user.login,
                        description: values.short_description,
                        token: JwtService.getToken(),
                        fn: (result) => {
                            result.success ? toast.success("Запит успішно доданий. Найближчим часом наш менеджер з Вами зв'яжеться!") : toast.error(result.message);

                            in_progress.value = false;
                        },
                    });
                }
            });
        };

        return {
            userData,
            userPaymentsList,
            userPaymentsStatistics,
            sendNoMoneyRequest,
            in_progress,
            no_money_request,
        };
    },
});
